/* eslint-disable react/prop-types */
import React, { useState, useEffect, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";

import { useApi } from "@growthos/ui-app-sdk";
import Card, { CARD_PADDING } from "@growthos/ui-card";
import Heading, { HEADING_LEVEL, HEADING_SIZE } from "@growthos/ui-heading";
import Button, { BUTTON_DISPLAY, BUTTON_SIZE } from "@growthos/ui-button";
import SideSheet from "@growthos/ui-sidesheet";

import ApiApp from "../../services/ApiApp";

import "./PageHome.scss";

const PageHome = () => {
	const location = useLocation();

	const { isLoading, data } = useApi((ac) => ApiApp.getHomeData({ abortController: ac }), []);
	// console.log("PageHome::getHomeData", { isLoading, data });

	const [stateSelectedModuleName, setStateSelectedModuleName] = useState(null);
	const [stateIsSideSheetOpen, setStateIsSideSheetOpen] = useState(false);

	const { home: dataHome, ...dataModules } = data || {};
	const selectedModule = dataModules[stateSelectedModuleName];

	// Open the side sheet if the hash is a valid module nme
	useEffect(
		function handlerWindowHashChange() {
			const moduleNames = Object.keys(dataModules);
			const hashModuleIndex = moduleNames.map((moduleName) => `#${moduleName.toLowerCase()}`).indexOf(location.hash);
			if (hashModuleIndex !== -1) {
				setStateSelectedModuleName(moduleNames[hashModuleIndex]);
				setStateIsSideSheetOpen(true);
			}
		},
		[dataModules, location]
	);

	const { nextId, prevId } = useMemo(() => {
		const moduleNames = Object.keys(dataModules);
		const moduleIndex = moduleNames.indexOf(stateSelectedModuleName);
		if (moduleIndex === -1) {
			return { nextId: null, prevId: null };
		}
		const prevIndex = (moduleIndex + 1) % moduleNames.length;
		const nextIndex = (moduleIndex - 1 + moduleNames.length) % moduleNames.length;
		return {
			prevId: moduleNames[prevIndex],
			nextId: moduleNames[nextIndex]
		};
	}, [dataModules, stateSelectedModuleName]);

	function handlerSideCloseClick() {
		setStateIsSideSheetOpen(false);
		window.location.hash = "";
	}

	// TODO: Loading state
	if (isLoading) {
		return null; // <div>Loading...</div>;
	}

	return (
		<div id="pageHome" className="page">
			{/* Page */}
			<div className="content-left">
				{dataHome.sectionsLeft.map(({ id: definedId, title, style, ...props }) => {
					const id = definedId || _generateId(title);

					return (
						<Card key={id} cardPadding={CARD_PADDING.EXTRA_LARGE} style={style}>
							<SectionLeft id={id} title={title} {...props} />
						</Card>
					);
				})}
			</div>
			<div className="content-right">
				<Card cardPadding={CARD_PADDING.MEDIUM}>
					{dataHome.sectionsRight.map(({ id: definedId, title, ...props }, index) => {
						const id = definedId || _generateId(title);

						return (
							<React.Fragment key={id}>
								<SectionRight id={id} title={title} {...props} />
								{index !== dataHome.sectionsRight.length - 1 && <hr className="hr" />}
							</React.Fragment>
						);
					})}
				</Card>
			</div>

			{/* SideSheet */}
			{selectedModule && (
				<SideSheet
					className="sidesheet"
					headline={selectedModule.title}
					headerActions={
						<>
							{nextId && prevId && (
								<div className="btn-wrap">
									<Button as={Link} to={`#${nextId}`} display={BUTTON_DISPLAY.ICON_BORDERED} size={BUTTON_SIZE.EXTRA_SMALL} icon="icon-arrow-left">
										Previous
									</Button>
									<Button as={Link} to={`#${prevId}`} display={BUTTON_DISPLAY.ICON_BORDERED} size={BUTTON_SIZE.EXTRA_SMALL} icon="icon-arrow-right">
										Next
									</Button>
								</div>
							)}
						</>
					}
					isOpen={stateIsSideSheetOpen}
					onClose={handlerSideCloseClick}
				>
					{selectedModule.color && <div className="sidesheet-color" role="presentation" style={{ backgroundColor: selectedModule.color }}></div>}

					<div id={_generateId(selectedModule.title)}>
						<p>{selectedModule.description}</p>
					</div>

					{selectedModule.sections && selectedModule.sections.length > 0 && (
						<>
							{selectedModule.sections.map(({ hidden, id: definedId, title, description, tiles }) => {
								if (hidden) {
									return null;
								}
								const id = definedId || _generateId(title);
								const Tag = title ? "section" : "div";

								return (
									<React.Fragment key={id}>
										{Tag === "div" && <hr className="hr" />}
										<Tag id={id} className="section-sidesheet">
											{title && (
												<Heading as={HEADING_LEVEL.H3} size={HEADING_SIZE.SMALL} description={description}>
													{title}
												</Heading>
											)}
											<Tiles tiles={tiles} />
										</Tag>
									</React.Fragment>
								);
							})}
						</>
					)}
				</SideSheet>
			)}
		</div>
	);
};
export default PageHome;

function SectionLeft({ id, title, description, grid, tiles, sections, depth = 0 }) {
	const { headingLevel, headingSize } = useMemo(() => {
		switch (depth) {
			case 1:
				return { headingLevel: HEADING_LEVEL.H3, headingSize: HEADING_SIZE.MEDIUM };
			case 0:
			default:
				return { headingLevel: HEADING_LEVEL.H2, headingSize: HEADING_SIZE.LARGE };
		}
	}, [depth]);

	return (
		<section id={id} className="section-left">
			<Heading as={headingLevel} size={headingSize} description={description}>
				{title}
			</Heading>

			<Tiles tiles={tiles} grid={grid} />

			{sections &&
				sections.length > 0 &&
				sections.map(({ hidden, id: definedId, title, ...props }) => {
					if (hidden) {
						return null;
					}
					const id = definedId || _generateId(title);

					return (
						<React.Fragment key={id}>
							<hr className="hr" />
							<SectionLeft id={id} title={title} {...props} depth={depth + 1} />
						</React.Fragment>
					);
				})}
		</section>
	);
}

function SectionRight({ id, title, description, tiles }) {
	const Tag = title ? "section" : "div";

	return (
		<Tag id={id} className="section-right">
			{title && (
				<Heading as={HEADING_LEVEL.H3} size={HEADING_SIZE.SMALL} description={description}>
					{title}
				</Heading>
			)}
			<Tiles tiles={tiles} />
		</Tag>
	);
}

function Tiles({ tiles, grid = 0 }) {
	return tiles && tiles.length > 0 ? (
		<ul className={grid ? `tiles tiles-grid tiles-grid-${grid}` : "tiles"}>
			{tiles.map(({ id, name, ...props }) => (
				<li key={id || _generateId(name)}>
					<Tile name={name} {...props} />
				</li>
			))}
		</ul>
	) : null;
}

// eslint-disable-next-line complexity
function Tile({ hidden = false, name, description, color, image, icon, link, links }) {
	if (hidden) {
		return null;
	}
	const isLink = link && !link.text; // If link has text then add a button otherwise the tile is the link
	const hasLinks = links && links.length > 0;

	return (
		<div className={["tile", icon && "tile-has-icon", hasLinks && "tile-has-links"].join(" ").replace(/\s+/, " ").trim()}>
			{image && image.src && (
				<div className="tile-img-color" style={color ? { backgroundColor: color } : {}}>
					<img src={image.src} alt={image.alt} />
				</div>
			)}

			{icon && <i className={`icon-${icon}`}></i>}

			<div className={isLink ? "tile-title tile-title-link" : "tile-title"}>
				{isLink ? (
					<Link to={link.href}>
						<span>{name}</span>
					</Link>
				) : (
					<h4>{name}</h4>
				)}
				<p>{description}</p>
			</div>

			{hasLinks && (
				<ul className="tile-links">
					{links.map(({ text, href }) => (
						<li className="tile-link" key={_generateId(text)}>
							<a href={href} target="_blank" rel="noreferrer">
								<span>{text}</span>
								<i className="icon-export-alt"></i>
							</a>
						</li>
					))}
				</ul>
			)}

			{link && link.href && !isLink && (
				<>
					{link.icon || link.display ? (
						<Button
							as="a"
							href={link.href}
							target="_blank"
							display={link.display || BUTTON_DISPLAY.SECONDARY}
							size={link.size || BUTTON_SIZE.SMALL}
							icon={link.icon ? `icon-${link.icon}` : null}
						>
							{link.text}
						</Button>
					) : (
						<a className="tile-link-text" href={link.href} target="_blank" rel="noreferrer">
							<span>{link.text}</span>
						</a>
					)}
				</>
			)}
		</div>
	);
}

function _generateId(str) {
	if (!str) {
		throw new Error("id required");
	}
	return str
		.toLowerCase()
		.replace(/[']/g, "")
		.replace(/[^\w-]+/g, "-");
}
